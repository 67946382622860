<template>
  <div class="dashboard">
    <div class="row mb-4">
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p  class="text-uppercase fw-medium text-muted text-truncate mb-0" >
                 ALLOCATIONS
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4" >
              <div>
                <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.allocations.total}}
                </h4>
                <router-link to="/allocations" class="small text-decoration-underline"
                  >See details</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-info rounded fs-3">
                  <i class="ri-sort-desc text-info"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                  TOTAL HOSPITALS
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4" >
              <div>
                <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.hospitals}}
                </h4>
                <router-link to="/hospitals" class="small text-decoration-underline" >See details</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-primary rounded fs-3">
                  <i class="mdi mdi-hospital-building text-primary"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p class="text-uppercase fw-medium text-muted text-truncate mb-0" >
                  ALL USERS
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h4 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.users.total}}
                </h4>
                <router-link to="/users" class="text-decoration-underline" >See details</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-warning rounded fs-3">
                  <i class="ri-group-line text-warning"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-md-6">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex align-items-center">
              <div class="flex-grow-1 overflow-hidden">
                <p class="text-uppercase fw-medium text-muted text-truncate mb-0">
                  TOTAL MEDICINES
                </p>
              </div>
            </div>
            <div class="d-flex align-items-end justify-content-between mt-4">
              <div>
                <h5 class="fs-22 fw-semibold ff-secondary mb-4">
                  {{result.medicines }}
                </h5>
                <router-link to="/medicines" class="small text-decoration-underline"
                  >View list</router-link>
              </div>
              <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-danger rounded fs-3">
                  <i class="mdi mdi-medical-bag text-danger"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-4">
      <div class="col-md-6">
        <statistic-apex-count
          title="ALLOCATIONS IN PROGRESS"
          description="Allocated quantities are not completely confirmed"
          :count-end="result.allocations.in_progress"
          :total="result.allocations.total"
          :figure="result.allocations.in_progress"
          chart-color="#FFB02f"
        />
      </div>
      <div class="col-md-6">
        <statistic-apex-count
          title="DISPATCHED ALLOCATIONS"
          description="Allocated quantities are completely confirmed"
          :count-end="result.allocations.dispatched"
          :total="result.allocations.total"
          :figure="result.allocations.dispatched"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <top-medicine-card />
      </div>
      <div class="col-md-6">
        <top-ailment-card />
      </div>
    </div>
  </div>
</template>

<script>

import StatisticApexCount from '@/components/widgets/StatisticApexCount.vue';
import TopMedicineCard from '@/components/cards/TopMedicineCard.vue';
import TopAilmentCard from '@/components/cards/TopAilmentCard.vue';

export default {
    name: 'dashboard',
    components:{
      StatisticApexCount,
      TopMedicineCard,
      TopAilmentCard 
    },
    computed:{
      activeUser(){
        return this.$store.state.AppActiveUser
      },
      result(){
        return this.$store.state.dashboardData
      }
    },
}
</script>

